.input-group-wrapper__3-options {
	.input-group__3-options-header {
		margin-bottom: 8px;
	}

	.three-options-input-group {
		.input-group-wrapper__3-options {
			display: inline-flex;
			align-items: center;
			margin-right: $space-lg;
			margin-bottom: 12px;
			
			p {
				margin: 0 $space-xs 0 0;
				font-family: $body-font-family;
			}

			.input-group__radio {
				min-width: unset;
				margin: 12px 0;

				input {
					display: none;
				}
				
				label {
					margin: 0;
					padding: 10.5px 16px;
					background-color: $grey-1;
					line-height: initial;
					cursor: pointer;
					font-family: $body-font-family;
					font-weight: $text-bold;
					font-size: $text-sm;
					border: 1px solid $grey-4;
					
					&:hover {
						background-color: $grey-4;
					}
				}

				&:first-of-type {
					label {
						border-top-left-radius: 22px;
						border-bottom-left-radius: 22px;
						padding-left: 20px;
						box-shadow: inset 0px 0px 0px 0px $grey-2;
					}
				}

				&:last-of-type {
					label {
						border-top-right-radius: 22px;
						border-bottom-right-radius: 22px;
						padding-right: 20px;
					}
				}
				
				input:checked {
					+ label {
						color: $white;
					}
				}
				
				&.bad > input:checked {
					+ label {
						background-color: $color-support-red;
					}
				}

				&.neutral > input:checked {
					+ label {
						background-color: $grey-10;
					}
				}

				&.good > input:checked {
					+ label {
						background-color: $color-secondary;
					}
				}
			}
		}
	}
}