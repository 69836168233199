.radio-question {
	h4 {
		margin-bottom: 8px;
	}

	&.radio-group--horizontal {
		.radio-options-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;

			.input-group__radio {
				display: flex;
				flex-direction: column-reverse;
				min-width: unset;

				label {
					margin: 0 $space-sm 10px;
				}
			}
		}
	}
}