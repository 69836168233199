.slides-copyright__container {
	padding: 0 72px;
}

.slides-copyright {
	font-size: $text-sm;
}

.question__error {
	color: $color-support-red;
	font-weight: bold;
	padding: 0;
}

.slide-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 72px;
	background-color: white;
	z-index: 2;
	box-shadow: 0 4px 24px rgba(123, 123, 123, 0.15);
	position: sticky;
	top: 0;
	width: 100%;

	.slider-progress-bar {
		width: 400px;
		height: 16px;
		border-radius: 0;
		margin: auto;

		.bar {
			height: 16px;
			border-radius: 0;
			background-color: $color-secondary;
		}
	}

	&.slide-header-start {
		padding: 32px 72px;
		box-shadow: none;
	}
}

.slides-landing-img {
	width: 550px;
}

.slide-button__container {
	display: flex;
	column-gap: 24px;
	position: fixed;
	bottom: 32px;
	right: 48px;
}

.slides-landing-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 48px;
	height: 100%;
}

.slides-content__container {
	padding-left: 72px;
	margin-top: 40px;
	z-index: 1;
}

.slides-landing-body {
	padding-left: 72px;
	z-index: 1;
    h5 {
        color: $color-secondary-dark;
    }

	div {
		padding-bottom: 48px;
	}
}

.qr-code-wrapper {
    background-color: white;
	width: 175px;
	height: auto;
	position: absolute;
	bottom: 16px;
	right: 48px;
	text-align: center;
    padding: 16px;

	img {
		max-width: 100%;
	}

	h6 {
		margin: 4px;
	}
}


.form-slide .form__question-container {
	margin-bottom: 56px;
}

.form__endscreen-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.form__endscreen-content {
		max-width: 512px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 16px;

		img {
			max-width: 80%;
			margin-bottom: 24px;
		}
	}
}

.slides-copyright-endscreen__container {
	display: flex;
	gap: 72px;
	justify-content: center;
	align-items: center;
	padding: 24px 48px;
}

.hide-large {
	display: none;
}


@include media(sm, md) {
	.hide-large {
		display: none;
	}

	.slides-copyright {
		font-size: $text-xsm;
	}

	.slide-header {
		padding: 16px 32px;
	}

	.slides-landing-img {
		width: 375px;
	}

	.slides-landing-body {
		padding: 32px;
	}

	.slides-landing-content {
		gap: 16px;
	}

	.slides-copyright__container {
		padding: 0 32px;
	}

	.slides-content__container {
		padding: 0 32px;
		margin-top: 64px;
	}

	.slide-button__container {
		right: 32px;
	}

	.slide-header {
		&.slide-header-start {
			padding: 0 32px;
			box-shadow: none;
		}
	}
}

@include media(xs, sm) {
	.hide-large {
		display: none;
	}
	.slides-landing-body {
		div {
			padding-bottom: 32px;
		}

		button {
			z-index: 1;
		}
	}

	.slides-landing-img {
		width: 512px;
		margin-right: -200px;
	}

	.slides-copyright {
		font-size: $text-xsm;
	}

	.slide-header {
		padding: 12px 32px;

		img {
			height: 55px;
		}

		p {
			margin-bottom: 0;
		}
	}

	.slides-content__container {
		margin-top: 48px;
		padding: 1rem;
	}

	.slide-header {
		&.slide-header-start {
			padding: 8px 32px;
			box-shadow: none;
		}
	}
}

@media only screen and (max-width: 512px) {
	.hide-large {
		display: inherit;
	}
	.hide-small {
		display: none;
	}

	.slider-progress-bar {
		display: none;
	}

	.qr-code-wrapper {
		display: none;
	}

	.slider-img-positioned {
		display: none;
	}

	.slide-button__container {
		bottom: 0;
		right: 0;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 48px;
		padding: 0 16px;
	}

	.slides-landing-img {
		display: none;
	}

	.slides-copyright__container {
		padding: 0 16px;
	}

	.slides-copyright-endscreen__container {
		flex-direction: column-reverse;
		gap: 16px;
		padding: 24px;
	}
}
