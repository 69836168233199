/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Search
-------------------------------- */
/*
Found above collection list 
*/

// % protected region % [Change search styles here] on begin
.search {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	height: auto;
	gap: 8px;

	.icon-cross {
		display: none;
	}

	.input-group {
		@include lightning-icon(before, search);

		&:before {
			position: absolute;
			top: 1.4rem;
			left: 1.2rem;
			z-index: 1;
		}
	}

	input[type="text"] {
		padding: $space-xs $space-xs $space-xs $space-lg;
		margin: 0;
		line-height: 1.9;

		&:active,
		&:focus {

			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&:-ms-input-placeholder {
				opacity: 0;
				@include fadeOut($animation-time);
			}
		}
	}

	.btn {
		padding: $space-sm;
	}
}
// % protected region % [Change search styles here] end

// % protected region % [Add additional search styles here] off begin
// % protected region % [Add additional search styles here] end