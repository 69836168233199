@import '../abstracts/spacing.scss';
@import '../abstracts/colors.scss';
@import '../abstracts/typography.scss';
@import '../abstracts/mixins.scss';


// Layout
$first-row-height: 132px;

// Charts
$chart-icon-size: 80px;
$border-radius: 4px;

.dashboard-background {
	background-color: #EFF0F1;
	padding: $space-md;
	overflow-y: scroll;
}

.slide-menu.ui.menu {
	margin: 0;
	height: 48px;
	/* Adds a Scrollbar to the menu */
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

    a.item {
        padding: $space-xs;
        color: $grey-8;
        &.active, &:hover {
            color: $color-primary;
            border-bottom-color: $color-primary;
        }
    }
}

.dashboard-page {
	min-width: 1200px;
	width: 1280px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 48px;

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 28px;

		h1:first-child {
			display: inline-block;
			max-width: calc(100% - 450px);
			margin: 0px;
		}

		.export-csv-btn {
			float: right;
			height: convert-rem(52px);
		}
	}


	.dashboard-slide-header {
		margin: calc($space-sm + $space-xxxs) 0px calc($space-md + $space-xxxs) 0px;
		height: 48px;

		h3:first-child {
			display: inline-block;
			margin: 0px;
			margin-top: calc((48px - $text-xl) / 4);
			max-width: calc(100% - 450px);
		}

		div:last-child {
			float: right;
			display: flex;

			.general-date-range-picker {
				margin: 0px;
				margin-right: $space-xs;
				display: flex;
				align-items: center;

				label {
					margin-right: $space-xs;
				}
				input {
					margin: 0px;
				}
			}
		}
	}

	.chart-container {
		display: grid;
		gap: $space-md $space-md;
		grid-template-columns: repeat(6, 1fr);
		grid-auto-columns: 2fr;

		.full-width {
			grid-column-start: span 6;
		}

		.half-width {
			grid-column-start: span 3;
		}

		.third-width {
			grid-column-start: span 2;
		}

		.hidden {
			display: none;
		}
	}

	.dashboard-chart {
		max-width: 100%;
		padding: $space-sm $space-sm $space-sm $space-sm;
		background: $white;
		border-radius: $border-radius;

		.label-container {
			vertical-align: middle;
			display: inline-block;

			.flex-container {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: center;

				h3, p {
					margin: 0px;
				}

				span {
					font-style: normal;
					font-weight: 400;
					font-size: 11px;
					line-height: 13px;
					color: #7B7B7B;
				}
			}
		}
	}

	@mixin ant-percent-ring {
		.ring-container {
			vertical-align: middle;
			display: inline-block;
			height: $chart-icon-size;
			width: $chart-icon-size;
			margin: $space-sm;

			.perc-icon {
				@include lightning-icon(before, chart-line, true);
				font-size: $text-xl + 1px;
				width: $text-xl + 1px;
			}
		}
	}

	.nps-chart {
		height: $first-row-height;
		border-bottom: 4px solid $color-secondary;
		padding: 4px 0px 0px 0px;
		@include ant-percent-ring;

		&.green-highlight {
			border-bottom: 4px solid $color-secondary;

			.label-container > .flex-container > h3 {
				color: $color-secondary-dark;
			}

			.perc-icon {
				color: $color-secondary;
			}
		}

		&.red-highlight {
			border-bottom: 4px solid $color-support-red;

			.label-container > .flex-container > h3 {
				color: $color-support-red-dark;
			}

			.perc-icon {
				color: $color-support-red;
			}
		}
	}

	.rating-chart {
		height: $first-row-height;
		border-bottom: 4px solid $color-primary;
		padding: 4px 0px 0px 0px;
		@include ant-percent-ring;

		.label-container > .flex-container > h3,
		.perc-icon {
			color: $color-primary;
		}
	}

	.comments-chart, .submissions-chart, .metric-highlight-chart {
		height: $first-row-height;
		padding: calc($space-sm + 4px) $space-sm $space-sm $space-sm;
		border-bottom: 4px solid $color-primary;

		.icon-container {
			background-color: $color-primary-10;
			border-radius: 40px;
			vertical-align: middle;
			margin-right: $space-sm;
			display: inline-block;

			height: $chart-icon-size;
			width: $chart-icon-size;

			span {
				height: $chart-icon-size;
				width: $chart-icon-size;
				display: inline-block;
				font-size: $text-xl + 1px;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $color-primary;
			}

			.feedback {
				@include lightning-icon(before, feedback, true);
				&::before { margin-right: 0; }
			}
			.forms {
				@include lightning-icon(before, forms, true);
				&::before { margin-right: 0; }
			}
			.emoji {
				@include lightning-icon(before, emoji, true);
				&::before { margin-right: 0; }
			}
			.sad {
				@include lightning-icon(before, sad, true);
				&::before { margin-right: 0; }
			}

			.globe {
				@include lightning-icon(before, globe, true);
				&::before { margin-right: 0; }
			}
		}

		&.green-highlight {
			border-bottom: 4px solid $color-secondary;
			h3 {
				color: $color-secondary-dark;
			}
			.icon-container {
				background-color: rgba($color-secondary, 0.1);
				> span {
					color: $color-secondary;
				}
			}
		}

		&.blue-highlight {
			border-bottom: 4px solid $color-primary;
			h3 {
				color: $color-primary-dark;
			}
			.icon-container {
				background-color: rgba($color-primary, 0.1);
				> span {
					color: $color-primary;
				}
			}
		}

		&.grey-highlight {
			border-bottom: 4px solid $grey-10;
			h3 {
				color: $black;
			}
			.icon-container {
				background-color: rgba($grey-10, 0.1);
				> span {
					color: $grey-10;
				}
			}
		}

		&.orange-highlight {
			border-bottom: 4px solid $color-support-orange;
			h3 {
				color: $color-support-orange-dark;
			}
			.icon-container {
				background-color: rgba($color-support-orange, 0.1);
				> span {
					color: $color-support-orange;
				}
			}
		}

		&.red-highlight {
			border-bottom: 4px solid $color-support-red;
			h3 {
				color: $color-support-red-dark;
			}
			.icon-container {
				background-color: rgba($color-support-red, 0.1);
				> span {
					color: $color-support-red;
				}
			}
		}
	}

	.line-chart {
		height: 586px;

		.ant-parent {
			min-height: 400px;
		}
	}

	.bar-chart {
		height: 100%;
		.ant-parent {
			margin-bottom: $space-lg;
			&:last-child {
				margin-bottom: 0px;
			}
			height: 250px;
		}
	}

	.stacked-bar-chart {
		height: 100%;
		.ant-parent {
			margin-bottom: $space-lg;
			&:last-child {
				margin-bottom: 0px;
			}
			height: calc(18 * 70px);
		}
	}

	.line-chart, .bar-chart, .stacked-bar-chart, .comment-list-chart, .column-chart {
		grid-column-start: span 6;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);

		.slide-menu.menu {
			border-bottom: none;
			a {
				padding-top: $space-xxs;
				padding-bottom: $space-xxs;
				margin-top: 0px;
				margin-bottom: $space-sm;
			}
		}

		.header {
			height: 52px;
			position: relative;

			& > h5 {
				display: inline-block;
			}

			// when single item
			& > .export-chart-csv-btn {
				position: absolute;
				right: 0px;
				width: 32px;
				height: 32px;

				&::after {
					display: none;
				}
			}

			// when nested inside flex div of items
			div > .export-chart-csv-btn {
				width: 32px;
				height: 32px;

				&::after {
					display: none;
				}
			}

			& > div {
				display: inline-block;
				position: absolute;
				top: 0px;
				right: 0px;

				.input-group, .dropdown__container {
					margin: 0px;
					min-width: 155px;
					max-width: 155px;
					background-color: white;
				}

				.input-group {
					display: inline-block;
					margin-right: $space-xs;
				}
			}
		}
	}

	.column-chart {
		height: 586px;

		.ant-parent {
			height: 482px;
		}
	}

	.comment-list-chart {
		.scroll-container {
			max-height: 430px;
			overflow-y: scroll;
			border-radius: $border-radius;

			.spinner-container, .spinner-placeholder {
				height: 100px;
				.simple-loader {
					width: $space-lg;
					height: $space-lg;
				}
			}

			.spinner-placeholder {
				color: $grey-10;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.comment {
				padding: $space-xs;
				color: $black;
				margin-right: $space-lg;

				&:nth-child(odd) {
					background-color: rgba($grey-10, 0.15);
				}
			}
		}
	}

	input.flat-picker-input {
		background-color: white;
		border-radius: $border-radius;
	}
}
