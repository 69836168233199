.input-group-wrapper__scale {
	display: flex;
	flex-direction: column;

	.input-group__scale-header {
		margin-bottom: 8px;
	}

	.input-group-options-wrapper__scale {
		width: min-content;
		min-width: 350px;

		.input-group-options-values__scale {
			display: flex;
			flex-direction: row;
			user-select: none;

			.input-group__radio-header {
				display: block;
			}

			.input-group__radio {
				min-width: unset;
				min-height: 72px;
				display: flex;
				flex-flow: column-reverse;
				position: relative;
				justify-content: center;
				align-items: center;
				margin: 16px 0 9px;

				input {
					width: 64px;
					height: 64px;
					border-radius: 0;

					&:after {
						display: none;
					}
				}

				label {
					font-family: $heading-font-family;
					position: absolute;
					font-size: 31px;
					margin: 0;
					color: $black;
					background-color: transparent;
				}

				&:first-child {
					input {
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
					}
				}

				&:nth-last-child(1) {
					input {
						border-top-right-radius: 8px;
						border-bottom-right-radius: 8px;
					}
				}

				input:checked {
					height: 72px;
					width: 72px;
					opacity: 1;
					border-radius: 4px;
					margin-left: -4px;
					margin-right: -4px;
					z-index: 99;

					+ label {
						color: white;
						z-index: 100;
					}
				}
			}

			.mobile {
				display: flex;
				align-items: center;

				.scale-combobox {
					margin-top: 16px;
					margin-right: 25px;
					margin-bottom: 0;

					& > div {
						display: block;
					}
				}

				.score {
					width: 64px;
					height: 64px;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: $white;
					font-size: 31px;
					font-family: $heading-font-family;
					font-weight: $text-regular;
					line-height: initial;
				}
			}
		}

		.input-group-options-description__scale {
			display: flex;
			justify-content: space-between;

			h6 {
				display: inline-block;
				margin: 0;
			}
		}
	}

	&.nps {
		.input-group__radio {
			input {
				width: 64px;
				height: 64px;
				border-radius: 0;
				border: 1px solid rgba($color-support-red, 35%);
				background-color: rgba($color-support-red, 35%);

				&:after {
					display: none;
				}

				&:not(:checked):hover:enabled {
					background-color: rgba($color-support-red, 25%);
				}

				&:checked {
					border: 1px solid $color-support-red;
					background-color: $color-support-red;
				}
			}

			&:nth-child(8), &:nth-child(9) {
				input {
					border: 1px solid rgba($grey-10, 25%);
					background-color: rgba($grey-10, 25%);

					&:not(:checked):hover:enabled {
						background-color: rgba($grey-10, 15%);
					}
				}

				input:checked {
					border: 1px solid $grey-10;
					background-color: $grey-10;
				}
			}

			&:nth-last-child(2), &:nth-last-child(1) {
				input {
					border: 1px solid $color-secondary-35;
					background-color: $color-secondary-35;

					&:not(:checked):hover:enabled {
						background-color: $color-secondary-25;
					}
				}

				input:checked {
					border: 1px solid $color-secondary;
					background-color: $color-secondary;
				}
			}
		}

		.mobile{
			.score {
				background-color: $grey-10;

				&.poor {
					border: 1px solid $color-support-red;
					background-color: $color-support-red;
				}

				&.excellent {
					border: 1px solid $color-secondary;
					background-color: $color-secondary;
				}
			}
		}
	}

	&.rating {
		.input-group__radio {
			input {
				border: 1px solid rgba($grey-10, 25%);
				background-color: rgba($grey-10, 25%);

				&:not(:checked):hover:enabled {
					background-color: $color-primary-25;
				}

				&:checked {
					border: 1px solid $color-primary;
					background-color: $color-primary;
				}
			}
		}

		.mobile {
			.score {
				border: 1px solid $color-primary;
				background-color: $color-primary;
			}
		}
	}
}



