.password-input-icon {
    top: 52%;
}

.ui.selection.dropdown {
    background: white !important;
}

.ui.selection.dropdown>.dropdown.icon {
    line-height: 1 !important;
    top: inherit;
}

.ui.selection.dropdown>.search.icon {
    line-height: 1 !important;
    top: inherit;
}

.input-group input {
    background-color: white;
}