/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Colour Variables
-------------------------------- */
/* _colors.scss is dependent on this file using variables that are changable in projects
*/


// % protected region % [Set colours, accent colours, and greyscale colours] on begin
/* Main colors */
/* -------------------------------------------------*/
/* ----------Pick your brand colours here ----------*/
/* -------------------------------------------------*/
$admin-color-primary: rgb(38, 169, 224) !default; // #26A9E0 // Primary 500
$admin-color-secondary: rgb(140, 195, 66) !default; // #8CC342 // Secondary 500
$admin-color-brand-01: rgb(57, 57, 57) !default; // #393939 // Charcoal
$admin-color-brand-02: rgb(213, 213, 213) !default; // #D5D5D5; // Grey
$admin-color-brand-03: rgb(244, 244, 244) !default; //#F4F4F4 // Light grey

// Accent Colours
$admin-color-support-red: rgba(246, 70, 79, 1) !default; // #E84D38 // Support color red
$admin-color-support-green: rgba(35, 215, 158, 1) !default; // #38CC7D // Support color green
$admin-color-support-blue: rgba(76, 98, 240, 1) !default; // #009FDD // Support color blue
$admin-color-support-yellow: rgba(244, 220, 62, 1) !default; // #FBC200 // Support color yellow
$admin-color-support-orange: rgba(251, 164, 65, 1) !default; // #F27600 // Support color orange
$admin-color-support-purple: rgba(91, 15, 211, 1) !default; // #8623DF // Support color purple

// Shades - generated using chroma.js
// 12 steps https://gka.github.io/palettes/#colors=#121230,#f5f5f5%7Csteps=11%7Cbez=1%7CcoL=1
$admin-black: rgb(7, 51, 66) !default; // #073342
$admin-grey-10: rgb(123, 123, 123) !default; // #7B7B7B // Disabled 500
$admin-grey-8: rgba($grey-10, 0.55) !default; // #474747 // Disabled 55% opacity
$admin-grey-6: rgba($grey-10, 0.35) !default; // #6f6f6f // Disabled 35% opacity
$admin-grey-4: rgba($grey-10, 0.25) !default; // #989898 // Disabled 25% opacity
$admin-grey-2: rgba($grey-10, 0.15) !default; // #c5c5c5 // Disabled 15% opacity
$admin-grey-1: rgb(232, 232, 232) !default; // #E8E8E8 // Disabled 300
$admin-grey-0: rgb(239, 240, 241) !default; // #EFF0F1 // Grey
$admin-white: rgb(255, 255, 255) !default; // #ffffff

//Text color - do not use. Use for function 
$admin-color-text-dark: $admin-black;
$admin-color-text-light: $white;


/* Colour Variables */
$admin-color-shades: 10% !default;
$admin-color-alpha: 0.8% !default;
$admin-color-shadow: 0.2% !default;

/* Colour Variations */
$admin-color-primary-10: rgba($color-primary, 0.10) !default; // #C5E9F6 Primary 10% opacity
$admin-color-primary-25: rgba($color-primary, 0.25) !default; // #C5E9F6 Primary 25% opacity
$admin-color-primary-light: rgb(197, 233, 246) !default; // #C5E9F6 // Primary 300
$admin-color-primary-dark: rgb(30, 135, 179) !default; // #1E87B3 // Primary 700
$admin-color-primary-bg: rgba($admin-color-primary, $admin-color-alpha) !default;

$admin-color-secondary-10: rgba($color-secondary, 0.10) !default; // #8CC342 // Secondary 10% opacity
$admin-color-secondary-25: rgba($color-secondary, 0.25) !default; // #8CC342 // Secondary 25% opacity
$admin-color-secondary-35: rgba($color-secondary, 0.35) !default; // #8CC342 // Secondary 35% opacity
$admin-color-secondary-light: rgb(163, 207, 104) !default; // #A3CF68 // Secondary 300
$admin-color-secondary-dark: rgb(112, 156, 53) !default; // #709C35 // Secondary 700
$admin-color-secondary-bg: rgba($admin-color-secondary, $admin-color-alpha) !default;

$admin-color-brand-01-light: lighten($admin-color-brand-01, $admin-color-shades) !default;
$admin-color-brand-01-dark: darken($admin-color-brand-01, $admin-color-shades) !default;
$admin-color-brand-01-bg: rgba($admin-color-brand-01, $admin-color-alpha) !default;

$admin-color-brand-02-light: lighten($admin-color-brand-02, $admin-color-shades) !default;
$admin-color-brand-02-dark: darken($admin-color-brand-02, $admin-color-shades) !default;
$admin-color-brand-02-bg: rgba($admin-color-brand-02, $admin-color-alpha) !default;

/* Support colors */
$admin-color-support-red-light: lighten($admin-color-support-red, $admin-color-shades) !default;
$admin-color-support-red-dark: darken($admin-color-support-red, $admin-color-shades) !default;
$admin-color-support-red-bg: rgba($admin-color-support-red, $admin-color-alpha) !default;

$admin-color-support-green-light: lighten($admin-color-support-green, $admin-color-shades) !default;
$admin-color-support-green-dark: darken($admin-color-support-green, $admin-color-shades) !default;
$admin-color-support-green-bg: rgba($admin-color-support-green, $admin-color-alpha) !default;

$admin-color-support-blue-light: lighten($admin-color-support-blue, $admin-color-shades) !default;
$admin-color-support-blue-dark: darken($admin-color-support-blue, $admin-color-shades) !default;
$admin-color-support-blue-bg: rgba($admin-color-support-blue, $admin-color-alpha) !default;

$admin-color-support-yellow-light: lighten($admin-color-support-yellow, $admin-color-shades) !default;
$admin-color-support-yellow-dark: darken($admin-color-support-yellow, $admin-color-shades) !default;
$admin-color-support-yellow-bg: rgba($admin-color-support-yellow, $admin-color-alpha) !default;

$admin-color-support-orange-light: lighten($admin-color-support-orange, $admin-color-shades) !default;
$admin-color-support-orange-dark: darken($admin-color-support-orange, $admin-color-shades) !default;
$admin-color-support-orange-bg: rgba($admin-color-support-orange, $admin-color-alpha) !default;

$admin-color-support-purple-light: lighten($admin-color-support-purple, $admin-color-shades) !default;
$admin-color-support-purple-dark: darken($admin-color-support-purple, $admin-color-shades) !default;
$admin-color-support-purple-bg: rgba($admin-color-support-purple, $admin-color-alpha) !default;
// % protected region % [Set colours, accent colours, and greyscale colours] end
// % protected region % [Add additional colours here] off begin
// % protected region % [Add additional colours here] end