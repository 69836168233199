.login-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
}

.alert-toast-header {
    margin-top: 0;
    margin-bottom: 10px;
}