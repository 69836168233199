/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Sublinks navigation
-------------------------------- */
/*
Sublinks navigation are the lists that displays under an li link. Example of this is found in the admin navigation.
*/



// % protected region % [Change sublinks styles here] on begin
.nav__sublinks {
	opacity: 0;
	background-color: $admin-grey-10;
	color: $admin-white;
	position: absolute;
	z-index: 3;
	padding: 0;
	overflow: hidden; //do not remove this

	span {
		white-space: nowrap;
	}

	li {
		white-space: nowrap;
		font-weight: $admin-text-light;

		a {
			color: $admin-white;
		}

		&:last-of-type {
			border-bottom: none;
		}

		&:hover,
		&:active,
		&:focus {
			a {
				color: $admin-white;
				@include transform-timing($admin-animation-time);
			}
		}
	}

	&.nav__sublinks--visible {
		opacity: 1;

		span {
			opacity: 1;
		}
	}
}

// % protected region % [Change sublinks styles here] end
// % protected region % [Add additional sublinks styles here] off begin
// % protected region % [Add additional sublinks styles here] end