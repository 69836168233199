.input-group-wrapper__pill {
	width: 100%;
	.input-group__checkbox-header {
		margin-bottom: $space-xxs;
	}

	.tooltip {
		display: none;
	}

	.pill-checkbox-input-group {
		.pill-checkbox {
			display: inline-block;
			min-width: fit-content;
			margin: 12px 0;

			input {
				display: none;

				&:checked {
					+ label {
						border-color: $color-secondary;
						background-color: $color-secondary;
						color: $white;
						transition-duration: 0.1s;
					}
				}

				&:disabled {
					+ label:hover {
						background-color: transparent;
						border: 1px solid $grey-10;
					}
				}
			}

			label {
				border: 1px solid $grey-10;
				border-radius: 54px;
				padding: 8px 16px;
				margin: 0 24px 0 0;
				max-width: unset;
				background-color: white;

				&:hover {
					border-color: $color-secondary;
					background-color: $color-secondary-10;
				}

				.required {
					display: none;
				}
			}
		}
	}
}