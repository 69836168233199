/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/********** CRUD BUTTONS **********/


/********** CRUD CREATE NEW OR EDIT **********/

// % protected region % [Change form submission styles here] off begin
.crud-component {
	padding: $space-lg;

	.crud__create,
	.crud__edit,
	.crud__view {
		@include fadeIn(0.5s);

		.crud__header {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.crud__return {
				padding-left: 0;
				text-align: left;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}
		}

		.crud__form-container {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin-top: $space-sm;
			padding-bottom: $space-xl;

			fieldset {
				width: 100%;
			}

			.input-group {
				margin: $space-xs $space-md $space-xs 0;

				@include media(xs, sm, md) {
					width: 100%;
				}
			}

			.input-group__checkbox {
				margin-top: $space-lg;
			}

			.form-container__section {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				h6 {
					width: 100%;
					margin-bottom: 0;
					color: get-contrasting-text-color($background);
					border-bottom: convert-rem(2px) solid get-contrasting-text-color($background);
				}
			}
		}

		form {
			.input-checkbox {
				width: auto;
				display: flex;
				align-items: center;
				min-width: convert-rem(220px);
				margin-top: 0;
			}
		}
	}

	.form-errors {
		@include fadeIn(0.3s);
		color: $color-support-red;
	}
}

// % protected region % [Change form submission styles here] end

// % protected region % [Add additional form styles here] off begin
// % protected region % [Add additional form styles here] end